<script>
    export let items;
    export let preffix = '';
    let cursor = {
        x: 0,
        y: 0
    }
    let device = "mob";
    let innerWidth = 0;
    let innerHeight = 0;
    let active = false;
    let scrollY = 0;
    let balls;

    function handleMouseMove(ev){
        const {clientX, clientY} = ev;
        cursor.x = clientX;
        cursor.y = clientY;
    }
    $: if(innerWidth > 991){
        device = 'pc';
    }else if(innerWidth < 992 && innerWidth > 573){
        device = 'tab';
    }else{
        device = 'mob';
    }
    function handleScrollWindow(){
        // const clientHeight = document.documentElement.clientHeight;
        if(device == 'mob' || device == 'tab'){
            const y = balls.getBoundingClientRect().top;
            if(y < innerHeight - 200){
                scrollY = y - (innerHeight - 200);
            }
        }else{
            if(!active){
                if(balls.getBoundingClientRect().top < innerHeight - 500){
                    active = true
                }
            }
        }
    }
</script>
<svelte:window 
    on:mousemove={handleMouseMove} 
    bind:innerWidth
    bind:innerHeight
    on:scroll={handleScrollWindow}
/>
<div 
    class="balls" 
    bind:this={balls}
>
    {#if items}
        {#if device == 'pc'}
            {#each items.pc as {size, cords, colors, opacity, multiplier, startRelative}, index}
                <svg 
                    width={size} height={size} 
                    viewBox="0 0 {size} {size}" 
                    fill="none" 
                    style="
                        transform:translate(
                            {active ? 0 : startRelative.x}px,
                            {active ? 0 : startRelative.y}px
                        );
                        top:{Math.floor(cords.top + cursor.y * multiplier.y)}px;
                        right:{Math.floor(cords.right + cursor.x * multiplier.x)}px;
                        bottom:{Math.floor(cords.bottom + cursor.y * multiplier.y)}px;
                        left:{Math.floor(cords.left + cursor.x * multiplier.x)}px;
                    "
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <circle 
                        cx={size/2} cy={size/2} r={size/2} 
                        fill="url(#{preffix}ballGrad_{index})"
                    />
                    <defs>
                        <linearGradient 
                            id="{preffix}ballGrad_{index}" 
                            x1={size/2} y1="0" 
                            x2={size/2} y2={size} 
                            gradientUnits="userSpaceOnUse"
                        >
                            <stop 
                                stop-color={colors.top} 
                                stop-opacity={opacity.top}
                            />
                            <stop 
                                offset="1" 
                                stop-color={colors.bottom}
                                stop-opacity={opacity.bottom}
                            />
                        </linearGradient>
                    </defs>
                </svg>
            {/each}
        {:else}
            {#each items[device] as {size, cords, colors, opacity, multiplier, startRelative}, index}
                <svg 
                    width={size} height={size} 
                    viewBox="0 0 {size} {size}" 
                    fill="none" 
                    style="
                        top:{Math.floor(cords.top + scrollY * multiplier)}px;
                        right:{cords.right}px;
                        bottom:{Math.floor(cords.bottom - scrollY * multiplier)}px;
                        left:{cords.left}px;
                    "
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <circle 
                        cx={size/2} cy={size/2} r={size/2} 
                        fill="url(#ballGrad_{index})"
                    />
                    <defs>
                        <linearGradient 
                            id="ballGrad_{index}" 
                            x1={size/2} y1="0" 
                            x2={size/2} y2={size} 
                            gradientUnits="userSpaceOnUse"
                        >
                            <stop 
                                stop-color={colors.top} 
                                stop-opacity={opacity.top}
                            />
                            <stop 
                                offset="1" 
                                stop-color={colors.bottom}
                                stop-opacity={opacity.bottom}
                            />
                        </linearGradient>
                    </defs>
                </svg>
            {/each} 
        {/if}
    {/if}
</div>
<style lang="postcss">
    .balls{
        position: absolute 0;
        size:100%;
        z-index: 1;
    }
    svg{
        transition: transform 1s ease-out;
        transition: 
            all 0.15s ease-out,
            transform 1s ease-out
        ;
        position: absolute;
    }
</style>