// import Video from "./lib/home/Video.svelte";
import ProductGrid from "./lib/component/ProductGrid.svelte";
import HomeBalls from "./lib/component/ParallaxBalls.svelte";
// import Tabs from "./lib/component/Tabs.svelte";
import { get } from "./lib/utils/api";
// import { crawlerBot } from "./lib/utils/stores/states";

// if(!crawlerBot){
 
// }
get('/resources/json/home/products.json').then(data=>{
    new ProductGrid({
        target: document.querySelector('#catalog'),
        props:{
            list: data.items,
            rounded: false,
            gap: '16px'
        }
    })    
})
get('/resources/json/home/balls.json').then(data=>{
    new HomeBalls({
        target: document.querySelector('.info'),
        props:{
            items: data
        }
    })
})
